<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 language-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("global", "language", "language") }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper language-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('global', 'language', 'search')"
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <section class="languages" v-if="sortedLanguages && sortedLanguages.length">
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="lang.locale"
          v-for="lang in sortedLanguages"
          :key="lang.id"
        >
          <button
            class="clebex-item-content-wrapper"
            @click="selectLanguage(lang)"
          >
            <span class="label">{{ lang.name }}</span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  :icon="[
                    selectedLanguage && lang.id == selectedLanguage.id
                      ? '#cx-app1-checkmark'
                      : '#cx-app1-arrow-right-12x12'
                  ]"
                  :width="[
                    selectedLanguage && lang.id == selectedLanguage.id
                      ? '20'
                      : '12'
                  ]"
                  :height="[
                    selectedLanguage && lang.id == selectedLanguage.id
                      ? '20'
                      : '12'
                  ]"
                ></icon>
              </span>
            </span>
          </button>
        </li>
      </ul>
    </section>
  </mobile-screen>
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { mapActions, mapState } from "vuex";

export default {
  name: "LanguageSelection",
  data() {
    return {
      alphabet: [...Array(26)]
        .map((_, y) => String.fromCharCode(y + 65))
        .join(""),
      searchQuery: ""
    };
  },
  computed: {
    ...mapState("language", ["languages", "selectedLanguage"]),
    sortedLanguages() {
      if (this.languages && this.languages.data && this.languages.data.length) {
        let languages = this.languages.data.slice(0);

        const query = this.searchQuery;

        if (query && query.length > 1) {
          languages = languages.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        }

        return languages.sort((a, b) => {
          if (this.selectedLanguage && b.id == this.selectedLanguage.id) {
            return 1;
          }
          if (this.selectedLanguage && a.id == this.selectedLanguage.id) {
            return -1;
          }

          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      }
      return null;
    }
  },
  methods: {
    ...mapActions("language", ["setSelectedLanguage"]),
    selectLanguage(lang) {
      this.setSelectedLanguage(lang);
      this.$router.push({ name: this.backLinkName });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
